import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import useConsent, { EMBEDDINGS } from "../utility/hooks/UseConsent"

const useAnalytics = id => {
    const { isEmbeddingAccepted } = useConsent()
    const isGoogleTagManagerConsentGiven = isEmbeddingAccepted(
        EMBEDDINGS.GOOGLE_TAG_MANAGER
    )
    const isGoogleAnalyticsConsentGiven = isEmbeddingAccepted(
        EMBEDDINGS.GOOGLE_ANALYTICS_4
    )

    React.useEffect(() => {
        if (isGoogleTagManagerConsentGiven && isGoogleAnalyticsConsentGiven) {
            const script = document.createElement("script")
            script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
            script.async = true
            document.head.appendChild(script)
        }
    }, [isGoogleTagManagerConsentGiven, isGoogleAnalyticsConsentGiven])
}

export function SEO({ description, lang, meta, title }) {
    useAnalytics("G-THZMZ14Q2L")

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <script
                src="https://cloud.ccm19.de/app.js?apiKey=eccc31d42c00db444d32f4a477330525156ddcffeb9e788f&amp;domain=667c5d18e9ed757713093072"
                referrerpolicy="origin"
            ></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-THZMZ14Q2L');`}
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}
