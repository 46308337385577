import React from "react"
import cx from "classnames"

import * as styles from "~styles/atoms/grid.module.scss"

export const GridRow = ({ children, width, className }) => {
    return (
        <div
            className={cx(styles.gridRow, className, {
                [styles.gridRowMaxContentWidth]: width == "content",
            })}
        >
            {children}
        </div>
    )
}

export const GridColumn = ({
    children,
    s,
    m,
    l,
    largeSpacing = false,
    gutterBottom = false,
}) => {
    const sClass = `gridS${s}`
    const mClass = `gridM${m}`
    const lClass = `gridL${l}`

    return (
        <div
            className={cx(
                styles.gridColumn,
                styles[sClass],
                styles[mClass],
                styles[lClass],
                { [styles.gutterBottom]: gutterBottom },
                { [styles.gridColumnLargeSpacing]: largeSpacing }
            )}
        >
            {children}
        </div>
    )
}
