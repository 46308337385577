import React from "react"

export const EMBEDDINGS = {
    GOOGLE_ANALYTICS_4: "Google Analytics 4",
    MAPBOX: "Mapbox",
    GOOGLE_TAG_MANAGER: "Google Tag Manager",
    YOUTUBE: "YouTube Video",
    VIMEO: "Vimeo",
}

const useConsent = () => {
    const [consentGiven, setConsentGiven] = React.useState(false)
    const [fullConsentGiven, setFullConsentGiven] = React.useState(false)
    const [acceptedCookies, setAcceptedCookies] = React.useState([])
    const [acceptedEmbeddings, setAcceptedEmbeddings] = React.useState([])

    React.useEffect(() => {
        const updateConsent = () => {
            setConsentGiven(window.CCM.consent)
            setFullConsentGiven(window.CCM.fullConsentGiven)
            setAcceptedCookies(window.CCM.acceptedCookies)
            setAcceptedEmbeddings(window.CCM.acceptedEmbeddings)
        }

        window.addEventListener("ccm19WidgetLoaded", updateConsent)
        window.addEventListener("ccm19CookieAccepted", updateConsent)
        window.addEventListener("ccm19EmbeddingAccepted", updateConsent)
        window.addEventListener("ccm19WidgetClosed", updateConsent)

        return () => {
            window.removeEventListener("ccm19WidgetLoaded", updateConsent)
            window.removeEventListener("ccm19CookieAccepted", updateConsent)
            window.removeEventListener("ccm19EmbeddingAccepted", updateConsent)
            window.removeEventListener("ccm19WidgetClosed", updateConsent)
        }
    }, [])

    const isEmbeddingAccepted = embedding =>
        !!acceptedEmbeddings.find(
            acceptedEmbedding => acceptedEmbedding.name === embedding
        )

    return {
        isEmbeddingAccepted,
        consentGiven,
        fullConsentGiven,
        acceptedCookies,
        acceptedEmbeddings,
    }
}

export default useConsent
