import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import cn from "classnames"
import stickyLogo from "~images/logo/c.svg"
import { SubNavigationList } from "./Navigation/SubNavigationList"
import { MainNavigationList } from "./Navigation/MainNavigationList"

import {
    container as containerClass,
    containerIsSticky as containerIsStickyClass,
    mainNav as mainNavClass,
    mainNavIsSticky as mainNavIsStickyClass,
    mainNavList as mainNavListClass,
    mainNavListIsOpened as mainNavListIsOpenedClass,
    subNav as subNavClass,
    logo as logoClass,
    logoIsSticky as logoIsStickyClass,
    hamburger as hamburgerClass,
    hamburgerIsOpened as hamburgerIsOpenedClass,
} from "~styles/components/navigation.module.scss"

const Logo = ({ lang, siteTitle }) => (
    <Link to={`/${lang}`}>
        <img width="250" height="26" src={stickyLogo} alt={siteTitle} />
    </Link>
)

const Hamburger = ({ onClick, isOpened }) => (
    <div
        className={cn(hamburgerClass, { [hamburgerIsOpenedClass]: isOpened })}
        onClick={() => onClick()}
    >
        <span></span>
    </div>
)

/**
 *
 * @param {*} param0
 */
export const Navigation = ({ activeDocMeta, siteTitle }) => {
    const [isSticky, setIsSticky] = useState(false)
    const [isOpened, setIsOpened] = useState(false)

    const currentLang = activeDocMeta ? activeDocMeta.lang : "de-de"

    const handleScroll = () => {
        let threshold = window.pageYOffset > 130

        setIsSticky(threshold)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", () => handleScroll)
        }
    }, [])

    return (
        <div
            className={cn(containerClass, {
                [containerIsStickyClass]: isSticky,
            })}
        >
            <header>
                <div>
                    {!isSticky && (
                        <nav className={subNavClass}>
                            <SubNavigationList activeDocMeta={activeDocMeta} />
                        </nav>
                    )}
                    <div
                        className={
                            isSticky ? mainNavIsStickyClass : mainNavClass
                        }
                    >
                        <div
                            className={isSticky ? logoIsStickyClass : logoClass}
                        >
                            <Logo
                                lang={currentLang.slice(0, 2)}
                                siteTitle={siteTitle}
                            />
                            <Hamburger
                                isOpened={isOpened}
                                onClick={() => setIsOpened(!isOpened)}
                            />
                        </div>
                        <nav
                            className={cn(mainNavListClass, {
                                [mainNavListIsOpenedClass]: isOpened,
                            })}
                        >
                            <MainNavigationList activeDocMeta={activeDocMeta} />
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    )
}

Navigation.propTypes = {
    siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
    siteTitle: ``,
}
