import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Navigation } from "./header/Navigation"
import Footer from "~components/layout/Footer"

import "~styles/bundle.scss"
import * as styles from "~styles/elements/layouts/default-layout.module.scss"

export const DefaultLayout = ({
    activeDocMeta,
    children,
    variant = "bright",
}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const siteTitle = data.site.siteMetadata?.title

    return (
        <>
            <Navigation activeDocMeta={activeDocMeta} siteTitle={siteTitle} />
            <main
                className={
                    variant === "bright" ? styles.isBright : styles.isDark
                }
            >
                {children}
            </main>
            <Footer activeDocMeta={activeDocMeta} />
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
