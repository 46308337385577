import React from "react"
import LogoWhite from "~images/logo/logo_white.svg"
import Logo from "~images/logo/c.svg"
import IconLinkedin from "~images/icon/icon-linkedin.svg"
import IconYoutube from "~images/icon/icon-youtube.svg"
import IconFacebook from "~images/icon/icon-facebook.svg"
import IconInstagram from "~images/icon/icon-instagram.svg"
import RedArrow from "~images/icon/icon-arrow-red.svg"

import * as styles from "~styles/components/footer.module.scss"
import { graphql, Link, useStaticQuery } from "gatsby"
import { resolveLink, translate } from "~utility/Sink"

const Footer = ({ activeDocMeta }) => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            navigations: allPrismicNavigation {
                edges {
                    node {
                        data {
                            type {
                                text
                            }
                        }
                        uid
                    }
                }
            }
            items: allPrismicNavigationItem {
                edges {
                    node {
                        id
                        lang
                        data {
                            layout
                            link {
                                type
                                uid
                                lang
                                url
                                document {
                                    ... on PrismicPage {
                                        data {
                                            parent_page {
                                                uid
                                            }
                                        }
                                    }
                                }
                            }
                            navigation {
                                uid
                            }
                            parent_item {
                                document {
                                    ... on PrismicNavigationItem {
                                        id
                                    }
                                }
                            }
                            show_on_mobile
                            show_on_desktop
                            sort_order
                            title {
                                text
                            }
                            url {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    const footerNavigationItems = data.items.edges
        .filter(item => item.node.lang === activeDocMeta.lang)
        .filter(item => item.node.data.navigation?.uid === "footer-navigation")
        .sort((a, b) => a.node.data.sort_order - b.node.data.sort_order)

    const parentItems = footerNavigationItems.filter(
        item => item.node.data.parent_item.document === null
    )

    return (
        <footer className={styles.footer}>
            <div className={styles.footerNav}>
                <img src={Logo} alt="centrotherm Logo" />
                <div>
                    <div className={styles.footerNavList}>
                        {parentItems.map((parentItem, index) => (
                            <div key={parentItem.node.id}>
                                <span className={styles.footerNavListHeadline}>
                                    {parentItem.node.data.title.text}
                                </span>
                                <ul>
                                    <>
                                        {footerNavigationItems
                                            .filter(
                                                item =>
                                                    item.node.data.parent_item
                                                        .document?.id ===
                                                    parentItem.node.id
                                            )
                                            .sort(
                                                (a, b) =>
                                                    a.node.data.sort_order -
                                                    b.node.data.sort_order
                                            )
                                            .map(item => (
                                                <li key={item.node.id}>
                                                    <Link
                                                        partiallyActive={true}
                                                        to={
                                                            item.node.data.link
                                                                .uid
                                                                ? resolveLink(
                                                                      item.node
                                                                          .data
                                                                          .link
                                                                  )
                                                                : item.node.data
                                                                      .url.text
                                                        }
                                                    >
                                                        {
                                                            item.node.data.title
                                                                .text
                                                        }
                                                    </Link>
                                                </li>
                                            ))}
                                        {index === parentItems.length - 1 && (
                                            <li key="consent-modal">
                                                <a
                                                    href="#"
                                                    onClick={event => {
                                                        event.preventDefault()
                                                        window.CCM.openWidget()
                                                    }}
                                                >
                                                    {translate(
                                                        "footer.consent_widget_open",
                                                        activeDocMeta.lang
                                                    )}
                                                </a>
                                            </li>
                                        )}
                                    </>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.footerContactBar}>
                <div>
                    <div className={styles.footerContactBarChannels}>
                        <div className={styles.footerContactBarChannelsPage}>
                            <div>
                                <a href="/de/kontakt">
                                    {translate(
                                        "footer.contact_us",
                                        activeDocMeta.lang
                                    )}
                                    <span>
                                        <img src={RedArrow} alt="" />
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className={styles.footerContactBarChannelsSocial}>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/centrotherm-international-ag/"
                            >
                                <img src={IconLinkedin} />
                            </a>
                            <a
                                target="_blank"
                                href="https://www.youtube.com/watch?v=bqCo7R5clDY"
                            >
                                <img src={IconYoutube} />
                            </a>
                            <a
                                target="_blank"
                                href="https://www.facebook.com/centrotherm-international-AG-266430304263463/"
                            >
                                <img src={IconFacebook} />
                            </a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/centrotherm_international/"
                            >
                                <img src={IconInstagram} />
                            </a>
                        </div>
                    </div>
                    <div className={styles.footerContactBarLogo}>
                        <img src={LogoWhite} alt="" />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
