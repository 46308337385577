// extracted by mini-css-extract-plugin
export var footer = "F_cj";
export var footerBottomLinks = "F_ct";
export var footerContactBar = "F_cn";
export var footerContactBarChannels = "F_cq";
export var footerContactBarChannelsPage = "F_cs";
export var footerContactBarChannelsSocial = "F_cr";
export var footerContactBarLogo = "F_cp";
export var footerNav = "F_ck";
export var footerNavList = "F_cl";
export var footerNavListHeadline = "F_cm";