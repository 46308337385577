import { useEffect, useRef, useState } from "react"
import * as translations from "../config/translations.json"
import * as routes from "../config/routes.json"

export const isBrowser = () => typeof window !== "undefined"

export const translate = (key, language) => {
    return translations[language.substring(0, 2)][key]
}

export const localizeRoute = (key, language) => {
    return routes[language.substring(0, 2)][key]
}

export const useIntersect = ({
    root = null,
    rootMargin = 0,
    threshold = 0,
}) => {
    const [entry, updateEntry] = useState({})
    const [node, setNode] = useState(null)

    let observer = useRef()

    useEffect(() => {
        observer.current = new window.IntersectionObserver(
            ([entry]) => updateEntry(entry),
            {
                root,
                rootMargin,
                threshold,
            }
        )
    }, [])

    useEffect(() => {
        const { current: currentObserver } = observer
        currentObserver.disconnect()

        if (node) currentObserver.observe(node)

        return () => currentObserver.disconnect()
    }, [node])

    return [setNode, entry]
}

/**
 *
 * @param {*} url
 * @param {*} param1
 */
export const getImage = (url, { width, height }) => {
    return `${url}&width=${width * 1.4}&height=${
        height > 0 ? height * 1.4 : height
    }&sharp=10&q=90`
}
const defaultLanguage = "de"

export const resolveLink = doc => {
    if (
        doc.url &&
        doc.url.length &&
        ![
            "solution",
            "product",
            "page",
            "news",
            "home",
            "general_meeting",
        ].includes(doc.type)
    )
        return doc.url

    const lang = doc.lang ? doc.lang.slice(0, 2) : defaultLanguage

    if (doc.type === "solution") {
        return `/${lang}/future-starts-here/` + doc.uid
    } else if (doc.type === "general_meeting") {
        return (
            `${routes[lang]["investor_relations.annual_general_meeting"]}/` +
            doc.uid
        )
    } else if (doc.type === "product") {
        return `/${lang}/equipment/` + doc.uid
    } else if (doc.type === "page") {
        let path = doc.uid
        if (
            doc.document &&
            doc.document.data.parent_page &&
            doc.document.data.parent_page.uid
        ) {
            path = doc.document.data.parent_page.uid + "/" + path
        }

        return `/${lang}/` + path
    } else if (doc.type === "news") {
        let lang = doc.lang.slice(0, 2)

        return `/${lang}/newsroom/` + doc.uid
    } else if (doc.type === "home") {
        return `/${lang}`
    }
}

export const getTextWithFormattedFormulas = text => {
    if (!text) return text
    return text
        .replace(/AlOx/g, "AlO<sub>x</sub>")
        .replace(/SiNx/g, "SiN<sub>x</sub>")
        .replace(/Al2O3/g, "Al<sub>2</sub>O<sub>3</sub>")
        .replace(/O2/g, "O<sub>2</sub>")
        .replace(/N2/g, "N<sub>2</sub>")
        .replace(/N2O/g, "N<sub>2</sub>O")
        .replace(/CO2/g, "CO<sub>2</sub>")
        .replace(/POCl3/g, "POCl<sub>3</sub>")
        .replace(/BBr3/g, "BBr<sub>3</sub>")
        .replace(/H2/g, "H<sub>2</sub>")
        .replace(/H2O/g, "H<sub>2</sub>O")
        .replace(/SiH4/g, "SiH<sub>4</sub>")
        .replace(/NH3/g, "NH<sub>3</sub>")
        .replace(/B2H6/g, "B<sub>2</sub>H<sub>6</sub>")
        .replace(/PH3/g, "PH<sub>3</sub>")
        .replace(/SiH2Cl2/g, "SiH<sub>2</sub>Cl<sub>2</sub>")
        .replace(/N2H2/g, "N<sub>2</sub>H<sub>2</sub>")
        .replace(/ArH2/g, "ArH<sub>2</sub>")
        .replace(/m2/g, "m<sup>2</sup>")
        .replace(/m3/g, "m<sup>3</sup>")
        .replace(/mm2/g, "mm<sup>2</sup>")
        .replace(/mm3/g, "mm<sup>3</sup>")
}

export const getRoute = ({ module, view }) => {}

/**
 *
 * @param {*} currentPos
 * @param {*} minEffect
 * @param {*} maxEffect
 * @param {*} minValue
 * @param {*} maxValue
 */
export const getParallaxFactor = (
    currentPos,
    minEffect,
    maxEffect,
    minValue,
    maxValue
) => {
    if (currentPos <= minValue) {
        return minEffect
    } else if (currentPos >= maxValue) {
        return maxEffect
    }

    let valueStep = (maxValue - minValue) / 200
    let effectStep = (maxEffect - minEffect) / 200
    let relativeValuePos = (currentPos - minValue) / valueStep
    let effect = effectStep * relativeValuePos

    return effect
}

/**
 * get variable from url hash #
 * @param {string} name variable name
 */
export const getLocationHashVariable = name => {
    if (!isBrowser()) {
        return ""
    }

    const query = window.location.hash.substring(1)
    const vars = query.split("&")

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=")

        if (decodeURIComponent(pair[0]) == name) {
            return decodeURIComponent(pair[1])
        }
    }

    return ""
}

/**
 * animated scrolling
 *
 * @param {number} scrollTargetY
 * @param {number} speed pixels scrolled per second
 * @param {string} easing easing equation to use
 */
export const scrollToY = (
    scrollTargetY,
    speed = 2000,
    easing = "easeOutSine"
) => {
    if (!isBrowser()) {
        return false
    }

    let scrollY = window.scrollY || document.documentElement.scrollTop,
        currentTime = 0

    // min 100ms, max 2000ms
    let time = Math.max(
        0.1,
        Math.min(Math.abs(scrollY - scrollTargetY) / speed, 2)
    )

    // https://github.com/danro/easing-js/blob/master/easing.js
    let easingEquations = {
        easeOutSine: pos => {
            return Math.sin(pos * (Math.PI / 2))
        },
        easeInOutSine: pos => {
            return -0.5 * (Math.cos(Math.PI * pos) - 1)
        },
        easeInOutQuint: pos => {
            if ((pos /= 0.5) < 1) {
                return 0.5 * Math.pow(pos, 5)
            }
            return 0.5 * (Math.pow(pos - 2, 5) + 2)
        },
    }

    // animation loop
    const tick = () => {
        currentTime += 1 / 60

        let p = currentTime / time
        let t = easingEquations[easing](p)

        if (p < 1) {
            window.requestAnimationFrame(tick)
            window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
        } else {
            window.scrollTo(0, scrollTargetY)
        }
    }

    // call it once to get started
    tick()
}
