import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import GlobeIcon from "~images/icon/icon-globe.svg"

import * as styles from "~styles/components/navigation.module.scss"
import { resolveLink } from "~utility/Sink"
import Helmet from "react-helmet"

export const SubNavigationList = ({ activeDocMeta }) => {
    const data = useStaticQuery(graphql`
        query SubNavigationQuery {
            navigations: allPrismicNavigation {
                edges {
                    node {
                        data {
                            type {
                                text
                            }
                        }
                        uid
                    }
                }
            }
            items: allPrismicNavigationItem {
                edges {
                    node {
                        id
                        lang
                        data {
                            layout
                            link {
                                type
                                uid
                                lang
                                url
                                document {
                                    ... on PrismicPage {
                                        data {
                                            parent_page {
                                                uid
                                            }
                                        }
                                    }
                                }
                            }
                            navigation {
                                uid
                            }
                            parent_item {
                                type
                                lang
                                id
                            }
                            show_on_mobile
                            show_on_desktop
                            sort_order
                            title {
                                text
                            }
                            url {
                                text
                            }
                        }
                    }
                }
            }
        }
    `)

    const items = data.items.edges
        .filter(item => item.node.lang === activeDocMeta.lang)
        .filter(item => item.node.data.navigation?.uid === "sub-navigation")
        .sort((a, b) => a.node.data.sort_order - b.node.data.sort_order)

    const currentLang = activeDocMeta ? activeDocMeta.lang : "de-de"
    const currentLangOption = (
        <Link
            className={styles.subNavItem}
            partiallyActive={true}
            activeClassName={styles.subNavItemIsActive}
            to={`/${currentLang.slice(0, 2)}`}
        >
            {currentLang.slice(0, 2).replace("zh", "中文")}
        </Link>
    )

    const alternateLangOptions =
        activeDocMeta && activeDocMeta.alternateLanguages ? (
            activeDocMeta.alternateLanguages.map(altLang => (
                <Link
                    className={styles.subNavItem}
                    partiallyActive={true}
                    activeClassName={styles.subNavItemIsActive}
                    to={resolveLink(altLang)}
                    key={altLang.url}
                >
                    {altLang.lang.slice(0, 2).replace("zh", "中文")}
                </Link>
            ))
        ) : (
            <span></span>
        )

    return (
        <ul>
            {items.map(item => (
                <li key={item.node.id}>
                    <Link
                        partiallyActive={true}
                        className={styles.subNavItem}
                        activeClassName={styles.subNavItemIsActive}
                        to={
                            item.node.data.link.uid
                                ? resolveLink(item.node.data.link)
                                : item.node.data.url.text
                        }
                    >
                        {item.node.data.title.text}
                    </Link>
                </li>
            ))}

            <li className={styles.subNavL18nSwitch}>
                {activeDocMeta && (
                    <Helmet>
                        {activeDocMeta.alternateLanguages.map(altLang => (
                            <link
                                rel="alternate"
                                href={resolveLink(altLang)}
                                hrefLang={altLang.lang.slice(0, 2)}
                                key={altLang.lang.slice(0, 2)}
                            />
                        ))}
                    </Helmet>
                )}
                <img width="20" height="20" src={GlobeIcon} alt="Sprache" />
                {currentLangOption}
                {alternateLangOptions}
            </li>
        </ul>
    )
}
